import React from "react"

const Banner = () => {
    return (
        <div>
             <div className="mx-auto w-full  flex h-68">
    <img src="https://i.postimg.cc/fyn87Sfc/treestrength.jpg" className="w-full h-54"></img>

    </div>
   
        </div>
    )
}


export default Banner