import React from "react"
import { useSelector } from "react-redux"
import CreateSpotForm from "../Get-Spot"
import SignupFormModal from "../SignupFormModal"
import Delete from "../DeleteSpot/deleteSpot"
import { useDispatch } from "react-redux"
import * as spotActions from "../../store/spots"
import { useEffect } from "react"
import * as sessionActions from "../../store/session"
import { useHistory } from "react-router-dom"

const Admin = () => {
    const history = useHistory()
    const trees = useSelector(state=>state.spots?.allTrees)
    const treesobj = Object.values(trees)
    const user = useSelector(state=>state.session.user)
    
    const dispatch = useDispatch()
    useEffect(()=>{
        
        dispatch(spotActions.getTrees())
        
        
    }, [dispatch])
    const logout = () => {
        return dispatch(sessionActions.logout()).then(history.push('/'))
    }
   const Delete =(id) => {
       
       return dispatch(spotActions.deleteTrees(id)).then(dispatch(spotActions.getTrees()))
    } 
    if (!user) return history.push('/admin')
    return (
        <div className="text-black">
        { !user ? (
            <div>
                    </div>
                ):(
                    <div>
                    <div className="  mt-5 mx-[12%] justify-around bg-amber-500 flex"> location
                    <span >
                    Message
                    </span>
                    <span>
                        ID
                    </span>
                        </div>
                        
            {treesobj?.map((tree) => (
                <div className="border-2 border-slate-900 mt-5 mx-[12%] bg-emerald-300 justify-around flex">
                        <span className="w-56">
                {tree?.location} 
                        </span>
                <span className="w-96">
                {tree?.message} 
                </span>
                {tree?.id}
                <button className="text-black border mb-9 rounded-xl p-[3px] bg-red-400 border-black ml-4 w-fit h-8" onClick={()=>Delete(tree.id)}>
                    Delete
                </button>
                </div>
                ))}
<CreateSpotForm />
              
<div className="w-full flex justify-end items-center">

            <button className="w-36 p-3 h-8 mr-[10%] text-white/90 mt-4  border text-xs shadow-sm shadow-slate-900 border-gray-700 bg-[#514f4de8] rounded-2xl flex justify-around items-center" onClick={()=>logout()}> Log Out 

          </button> 
          </div>
            </div>
        )}
    </div>
)
}
export default Admin