import React from "react"
import * as spotActions from "../../store/spots"
import { useSelector, useDispatch } from "react-redux"
import {useParams} from "react-router-dom"
import { useEffect } from "react"

import {Link} from "react-router-dom"
const SingleTree = () => {
    const dispatch = useDispatch()
    const tree = useSelector(state=>state.spots?.singleTree)
  
    const {treeId} = useParams()
 
    const Asia = "https://i.postimg.cc/NffZrJvx/Asiaregistry.jpg"
    const SouthAmerica ="https://i.postimg.cc/zvpMcWKW/SAregistry.jpg"
    const NorthAmerica = "https://i.postimg.cc/XqnmLxG4/NAregistry.jpg"
    const Canada = "https://i.postimg.cc/7Zcv4gcn/Canadaregistry.jpg"
    const Africa = "https://i.postimg.cc/Y9XQxtMY/Africaregistry.jpg"
    let title;
    let body;
    let link;
   if(tree.location === "Asia"){
    title = "Female Empowerment" 
    body = "We partner with a nonprofit that works with local NGOS to plant trees within Women led agriculture projects and movements. These trees empower communities, families, and the ecology. Countries where these trees are cultivated are India, Bhutan, Thailand. More information at "
    link = "https://onetreeplanted.org/collections/asia"
   }
   if(tree.location === "Africa"){
    title = "Female Empowerment" 
    body = "We partner with a nonprofit that works with local NGOS to plant trees within Women led agriculture projects and movements. These trees empower communities, families, and the ecology. Countries where these trees are cultivated are Rwanda, Malawi, Uganda. More information at "
    link = "https://onetreeplanted.org/collections/africa"
   }
   if(tree.location === "South America"){
    title = "Female Empowerment" 
    body = "We partner with a nonprofit that works with local NGOS to plant trees within Women led agriculture projects and movements. These trees empower communities, families, and the ecology. Countries where these trees are cultivated are Peru, Panama, Costa-rica. More information at "
    link = "https://onetreeplanted.org/collections/latin-america"
   }
   if(tree.location === "North America"){
    title = "Reclaiming Wild Spaces" 
    body = "We partner with a nonprofit that works with preserving wildlands within several National Parks in the United States of America. These trees reclaim and rewild wild spaces and support a flourishing ecology. More information at "
    link = "https://onetreeplanted.org/collections/united-states"
   }
   if(tree.location === "Canada"){
    title = "Reclaiming Wild Spaces" 
    body = "We partner with a nonprofit that works with preserving wildlands within the Canadian Forests. These projects focus specifically on replenishing  trees after forest fires and to support thriving succession of native species.These trees reclaim and rewild wild spaces and support a flourishing ecology. More information at "
    link = "https://onetreeplanted.org/collections/united-states"
   }
        
        useEffect(()=>{
 
            dispatch(spotActions.getTree(treeId))
            
            
          }, [])
          
          return (
            <div className="w-full">

  
      <div className="mt-9">

          
        <div className="bg-black rounded-xl mb-5 h-5  mt-3 flex mx-[20%] items-center justify-center text-white">Your Tree

        </div>
              {tree.location === "Asia" ? (
                <div className="">
                  <img src={Asia} className="w-96 items-center flex justify-center mx-auto" />
                </div>
              ): ( tree.location ==="South America" ? (
                <div>
                  <img src={SouthAmerica} className="w-96 items-center flex justify-center mx-auto"/>
                </div>
              ): ( tree.location ==="North America" ? (
                <div>
                  <img src={NorthAmerica} className="w-96 items-center flex justify-center mx-auto" />
                </div>
              ): ( tree.location ==="Canada" ? (
                <div>
                  <img src={Canada} className="w-96 items-center flex justify-center mx-auto" />
                </div>
                ):( tree.location ==="Africa" ? (
                  <div>
                    <img src={Africa} className="w-96 items-center flex justify-center mx-auto" />
                  </div>
              ):(
                <div></div>
                )
                ))
                ))} 
      <div className="flex items-center justify-center text-2xl  mt-9  ">

      
      </div>
      <div className="border-2 mx-[25%] justify-around flex items-center bg-amber-950/90 text-white  "> {tree.location}
            <span className="">
Tree #
              RPT00{tree.id}
            </span>
            

      </div>
      <div className="flex flex-col items-center justify-center mx-[5em] text-lg xl:text-2xl">
         <h4 className="xl:text-4xl mt-3 font-semibold text-slate-900">
          {title}
          </h4>
          <br>
          </br>
        <h6 className="text-sm xl:text-xl  mt-[-1%] text-slate-800 mb-5">
          {body}
          <Link to={link} className ="text-blue-500">

          {link}
          </Link>
        </h6>
        <div>
          <Link to={`/certificate/${treeId}`} className="text-blue-500 underline font-semibold">
         View/Print Your Certificate
          </Link>
        </div>
        </div>
      <div className=" mx-[20%] bg-black h-5 rounded-xl text-white items-center justify-center flex mt-5"> To The Warrior

      </div>
        <div className="w-full flex items-center justify-center  mt-3">
                <img src="https://i.postimg.cc/KYQwvw0L/sappling.webp" className="rounded-xl border-2 border-slate-600 h-24 flex   "></img>
                <span className="ml-5 w-96 bg-gray-200 p-2 text-black rounded-xl  text-align:center items-center flex">


                " {tree.message} "
                </span>
        </div>

             
            <div className="flex items-center justify-center  mt-4 ">

      <Link to="/tree">

      <button className="text-black border mb-9 rounded-xl p-[3px] bg-gray-400/50 border-black ml-4 w-fit"  > Go Back

      </button>
      </Link>
              
            </div>
      
            </div>
               
              
                
 
             
        </div>
        )}
                

export default SingleTree