import React from "react"
 import {useState, useEffect } from "react";
import {  useDispatch , useSelector} from "react-redux";

import * as spotActions from "../../store/spots";




function CreateSpotForm() {

  const dispatch = useDispatch();
  const user = useSelector(state=>state.session.user)
  const [location, setLocation] = useState("")
  const [forSurvivor, setForSurvivor] = useState(false)
  const [number, setNumber] = useState(0)
  const [message, setMessage] = useState("")
  const [errors, setErrors] = useState([]);




useEffect(()=>{
 
  dispatch(spotActions.getTrees())

 
}, [])
function setMessage1() {
setMessage("This Tree is purchased to honor a survivor of sexual assault and/or violence. This Tree is dedicated to their healing and the healing of the planet.")
}
function setMessage2() {
    setMessage("This Tree is purchased to honor myself as a survivor of sexual assault and/or violence. This Tree is dedicated to my own healing and the healing of the planet.")
    }
function setMessage3() {
        setMessage("This Tree is purchased to honor and support the movement of giving voice to survivors of sexual assault and/or violence. This Tree is a humble dedication to a larger movement dedicated to the healing of survivors and the healing of the planet.")
        }

const handleSubmit = (e) => {

   
  e.preventDefault();
  setErrors([]);
    if(!user) return setErrors(["You Must Be Logged in To Create A Tree"])
    if(location !== "Asia" && location !== "North America" && location !== "South America" && location !== "Canada" && location !== "Africa") return setErrors(["Check Location Spelling"])
   dispatch(spotActions.createTree({ location, number, forSurvivor, message}))

  .then(()=>dispatch(spotActions.getTrees()))
  .catch(async (res) => {
    const data = await res.json();
    if (data && data.errors) setErrors(data.errors);
  });

  
}

return (
    <div className="createForm">
      
      <h1 className="title text-2xl flex items-center justify-center mt-9" >Create New Tree</h1>
      <form onSubmit={handleSubmit} className="h-55 w-[98%] px-4 mt-3 ">
        <ul className="items-center flex justify-center">
          {errors.map((error, idx) => <li key={idx}>{error}</li>)}
        </ul>
        <label className="label mt-2 flex justify-center" for="honor">TO THE HONOR OF A SURVIVOR
            </label>
        <div className="w-full items-center justify-center flex">
          <input className="flex items-center justify-center ml-7 w-96 border-2 border-slate-500"
      
            type="radio"
            id="honor"
          name="Healing"
          onClick={setMessage1}
            value={"This Tree is purchased to honor a survivor of sexual assault and/or violence. This Tree is dedicated to their healing and the healing of the planet."}
       
          /><br></br>
          </div>
          <label className="label mt-2 flex justify-center  items-center" for="healing">TO THE HEALING OF MYSELF
            </label>
        <div className="w-full items-center justify-center flex">
          <input className="flex items-center justify-center ml-7 w-96 border-2 border-slate-500"
       
            type="radio"
            id="healing"
          name="Healing"
          onClick={setMessage2}
            value={"This Tree is purchased to honor myself as a survivor of sexual assault and/or violence. This Tree is dedicated to my own healing and the healing of the planet."}
           
       
          /><br></br>
          </div>
          <label className="label mt-2 flex justify-center items-center " for="empower">TO EMPOWER THE MOVEMENT
            </label>
        <div className="w-full items-center justify-center flex">
          <input className="flex items-center justify-center ml-7 w-96 border-2 border-slate-500"
       
            type="radio"
            id="empower"
            onClick={setMessage3}
            name="Healing"
          
            value={"This Tree is purchased to honor and support the movement of giving voice to survivors of sexual assault and/or violence. This Tree is a humble dedication to a larger movement dedicated to the healing of survivors and the healing of the planet."}
          
          />
          </div> <br></br>
        <label className='label mt-2 '>
        <div className="w-full items-center justify-center flex">
          <input className="flex items-center justify-center ml-7 w-96 border-2 border-slate-500"
          placeholder="location"
            type="text"
           
         value={location}
            onChange={(e) => setLocation(e.target.value)}
            required
          />
          </div>
        </label>
        
   
       
       
       
         
         
        <div className="flex w-full items-center justify-center">
        <button className="w-36 p-3 h-8  text-white/90 mt-4 ml-9  border text-xs shadow-sm shadow-slate-900 border-gray-700 bg-amber-900 rounded-2xl flex justify-around items-center" type="submit" >Create Spot</button>
        </div>
      </form>
    </div>
  );
  
}


export default CreateSpotForm;