
import {Link} from "react-router-dom"

import React from "react"
 import {useState, useEffect } from "react";
import {  useDispatch , useSelector} from "react-redux";
import * as spotActions from "../store/spots"
import { useHistory } from "react-router-dom";

export default function TreeFind() {
    const history= useHistory()
    const id = useSelector(state=>state.spots.allTrees)
    const dispatch = useDispatch()
    const Asia = "https://i.postimg.cc/MHhqbdDL/Asiaregistry.jpg"
    const SouthAmerica ="https://i.postimg.cc/0rR1Jr5R/SAregistry.jpg"
    const NorthAmerica = "https://i.postimg.cc/VkF5mP4L/NAregistry.jpg"
    const Canada = "https://i.postimg.cc/Dw7yCYFV/Canadaregistry.jpg"
    const [errors, setErrors] = useState([]);
    const [form, SetForm] = useState({
        location: "",
        number: 0,
        forSurvivor: false,
        message: "",
      })
      const [number, setNumber] = useState(null)
    
    const handleSubmit = (e) => {
        e.preventDefault();
        setErrors([]); 
        if(id[number] === undefined) {
           return  setErrors(["Tree not found please try again."])
        }
        dispatch(spotActions.getTrees())
        .catch(async (res) => {
            const data = await res.json();
            if (data && data.errors) setErrors(data.errors);
        });
        
        history.push(`/api/${number}`)
      }
 

   
      

    return (

        <div className="text-2xl md:text-sm">
    

           
            <div className="bg-black text-sm md:text-2xl mx-[15%] text-center rounded-xl mt-4 text-white"> Lookup Your Tree Below

            </div>
            <div className="border mx-[20%] h-fit flex  mt-2 p-2  shadow-sm shadow-slate-900 rounded-xl w-full items-center justify-center mx-auto">
                <img src="https://i.postimg.cc/ZR88hM98/sprout.png" className="h-[30%] w-[30%] p-4 "></img>
                <div className="flex flex-col w-fit h-fit mt-4 ">
                    <div className="text-black flex mb-4 text-2xl text-center font-serif">

                        To find any tree in the registry, simply input the tree number located on the certificate and hit submit.
                    </div>
                    <form onSubmit={handleSubmit}>
                    <ul className="flex justify-center items-center mr-[30%] text-lg text-red-700 ">
          {errors.map((error, idx) => (
            <li key={idx}>{error}</li>
          ))}
        </ul>

                        <div className="flex inline-flex items-center justify-end ml-5 xl:ml-[20%] lg:ml-[10%]">

                        <span className="  flex inline-flex text-sm lg:text-3xl justify-end items-center ">

                            RPT00
                            </span>
                        <input
                            type="number"
                            className="text-black text-sm md:text-2xl w-24 border border-black ml-[15%] justify-center items-center"
                            
                            onChange={(e) => setNumber(e.target.value)}
                            required
                            placeholder="Tree #"
                            
                            >
                        </input>
                                </div>
                       

                            <button className="text-black text-sm md:text-2xl border bg-gray-400/50 border-black ml-4 "  >
                                Submit
                            </button>
                    


                    </form>
                    <div className="flex flex-col border">


                    </div>
                </div>
            </div>
        </div>
    )
}