import React from "react"
import { useSelector, useDispatch } from "react-redux"
import { useParams } from "react-router-dom"
import { useEffect } from "react"
import * as spotActions from "../../store/spots"
import { Link } from "react-router-dom"

const Certificate = () => {
    const dispatch = useDispatch()
    const tree = useSelector(state=>state.spots?.singleTree)
    let title = ""
    let body=""
    let link = ""
    const {treeId} = useParams()
    const number = "Tree #RPT00" +treeId
    const healing = "my own healing and the healing of the planet"
    const message = "This Tree is purchased to honor and support the movement of giving voice to survivors of sexual assault and/or violence. This Tree is a humble dedication to a larger movement dedicated to the healing of survivors and the healing of the planet."
    if(tree.location === "Asia"){
        title = "Female Empowerment" 
        body = "We partner with a nonprofit that works with local NGOS to plant trees within Women led agriculture projects and movements. These trees empower communities, families, and the ecology. Countries where these trees are cultivated are India, Bhutan, Thailand. More information at "
        link = "https://onetreeplanted.org/collections/asia"
       }
       if(tree.location === "Africa"){
        title = "Female Empowerment" 
        body = "We partner with a nonprofit that works with local NGOS to plant trees within Women led agriculture projects and movements. These trees empower communities, families, and the ecology. Countries where these trees are cultivated are Rwanda, Malawi, Uganda. More information at "
        link = "https://onetreeplanted.org/collections/africa"
       }
       if(tree.location === "South America"){
        title = "Female Empowerment" 
        body = "We partner with a nonprofit that works with local NGOS to plant trees within Women led agriculture projects and movements. These trees empower communities, families, and the ecology. Countries where these trees are cultivated are Peru, Panama, Costa-rica. More information at "
        link = "https://onetreeplanted.org/collections/latin-america"
       }
       if(tree.location === "North America"){
        title = "Reclaiming Wild Spaces" 
        body = "We partner with a nonprofit that works with preserving wildlands within several National Parks in the United States of America. These trees reclaim and rewild wild spaces and support a flourishing ecology. More information at "
        link = "https://onetreeplanted.org/collections/united-states"
       }
       if(tree.location === "Canada"){
           title = "Reclaiming Wild Spaces" 
           body = "We partner with a nonprofit that works with preserving wildlands within the Canadian Forests. These projects focus specifically on replenishing  trees after forest fires and to support thriving succession of native species.These trees reclaim and rewild wild spaces and support a flourishing ecology. More information at "
           link = "https://onetreeplanted.org/collections/united-states"
        }
        useEffect(()=>{
            
            dispatch(spotActions.getTree(treeId))
            
            
        }, [])
        return (
            <div>

            <div className="flex justify-center items-center">
            <div className="text-green-900 flex absolute w-96 text-2xl font-serif mt-[-150px] justify-center">
            In honor of: 
            <span className="ml-2">

            {title}
            </span>
            </div>
            <div className="text-green-900 absolute text-3xl mt-[-50px]">
                {number}
            </div>
            <div className="absolute mt-[100px] justify-center items-center w-[600px] text-green-900">
                {message}
            </div>
            <div id='image'>

            <img id="image" className="flex w-[95%] justify-center items-center" src="https://i.postimg.cc/c1b81BVj/image.png"></img>
            </div>
            </div>
            <div className="text-3xl flex items-center justify-center " >

<button onClick={()=>window.print('image')} className="text-black text-sm md:text-2xl border bg-gray-400/50 rounded-xl p-1 shadow shadow-gray-300 mt-[100px] border-black ml-4 "> Print</button>
<Link to={`/api/${treeId}`}>
<button className="text-black text-sm md:text-2xl border bg-gray-400/50 rounded-xl p-1 shadow shadow-gray-300 mt-[100px] border-black ml-4 ">Go Back</button>
</Link>
        </div>
            </div>
    )
}
export default Certificate