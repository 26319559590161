import React, { useState } from "react";
import { useDispatch } from "react-redux";

import * as sessionActions from "../../store/session";
import "./SignupForm.css";
import { useHistory } from "react-router-dom";

function SignupFormModal() {
  const history = useHistory()
  const dispatch = useDispatch();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [key, setKey] = useState()
  const [errors, setErrors] = useState([]);


  const handleSubmit = (e) => {

    e.preventDefault();
    setErrors([]);
    if(key !== "3777"){
      return setErrors(["Invalid Key"]);}
      if (password.length < 6) {
    return setErrors(["Password must be at least 6 Charecters long"]);
      }
      if(password !== confirmPassword) {
        return setErrors(["Passwords do not match!"])
      }
     

       return dispatch(sessionActions.signup({ username, password })).then(history.push("/dashboard"))
      
      .catch(async (res) => {
        const data = await res.json();
        if (data && data.errors) setErrors(data.errors);
        if(!errors.length){
          history.push("/dashboard")
        }
      });
      history.push('/dashboard')
    };

  return (
    <>
      <h1 className="title text-2xl flex items-center justify-center mt-9">Sign Up New Admin</h1>
      <form
        onSubmit={handleSubmit}
        className="h-55 w-[98%] px-4 mt-3  "
      >
        <ul className="items-center flex justify-center">
          {errors.map((error, idx) => (
            <li key={idx}>{error}</li>
          ))}
        </ul>
        <label className="label ">
          
        </label>
        <label className="label mt-2 ">
        <div className="w-full items-center justify-center flex">
          <input
            className="flex items-center justify-center ml-7 w-96 border-2 border-slate-500"
            placeholder="User Name"
            type="text"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            required
          />
          </div>
        </label>
        
        <label className="label mt-2">
        <div className="w-full items-center justify-center flex">
          <input
            className="flex items-center justify-center ml-7 w-96 border-2 border-slate-500"
            placeholder="password"
            autoComplete="password"
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
          </div>
        </label>
        <label className="label mt-2">
        <div className="w-full items-center justify-center flex">
          <input
            className="flex items-center justify-center ml-7 w-96 border-2 border-slate-500"
            placeholder="confirm password"
            autoComplete="confirm-password"
            type="password"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            required
          />
          </div>
        </label>
        <label className="label mt-2">
        <div className="w-full items-center justify-center flex">
          <input
            className="flex items-center justify-center ml-7 w-96 border-2 border-slate-500"
            placeholder="key"
            autoComplete="key"
            type="number"
          
            name="key"
            onChange={(e) => setKey(e.target.value)}
            required
          />
          </div>
        </label>
        <div className="flex w-full items-center justify-center">
        <button className="w-36 p-3 h-8  text-white/90 mt-4 mr-4  border text-xs shadow-sm shadow-slate-900 border-gray-700 bg-[#514f4de8] rounded-2xl flex justify-around items-center" type="submit">
          Sign Up
        </button>
        </div>
      </form>
    </>
  );
}

export default SignupFormModal;
