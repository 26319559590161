import * as spotActions from '../../store/spots'
import {useDispatch, useSelector} from 'react-redux'
import { useEffect } from 'react'
import { useHistory } from 'react-router-dom'

function Delete(id) {
    const history = useHistory()
    const dispatch = useDispatch()

   return dispatch(spotActions.deleteTrees(id))
}

export default Delete