import React, { useState } from "react";
import * as sessionActions from "../../store/session";
import { useDispatch } from "react-redux";


import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
function LoginFormModal() {
  const history = useHistory()
  const dispatch = useDispatch();
  const [credential, setCredential] = useState("");
  const [password, setPassword] = useState("");
  const [errors, setErrors] = useState([]);
  const user = useSelector(state=>state.session?.user)
  if (user) history.push("/dashboard")

  const handleSubmit = (e) => {
    e.preventDefault();
    setErrors([]);
    console.log(user)
    
    // }
    
    if (password.length < 6) {
      return setErrors(["Password must be at least 6 Charecters long"]);
    }

  
  
    dispatch(sessionActions.login({ credential, password })).then(history.push("/dashboard"))
   if(user === null) {
    return setErrors(["No Such User"])
   }
   return history.push("/dashboard")
   .catch(async (res) => {
     const data = await res.json();
     
     setErrors(["Invalid Credentials Please Try Again"]);
     
     if (data) {
       return setErrors(data);
      }
      
    });
    
    return history.push("/dashboard")
  };

  return (
    <>
      <h1 className="title text-2xl flex items-center justify-center mt-9">Log In</h1>
      <form onSubmit={handleSubmit} className="h-55 w-[98%] px-4 mt-3 ">
        <ul className="items-center flex justify-center">
          {errors.map((error, idx) => (
            <li key={idx}>{error}</li>
          ))}
        </ul >
        <label className="label ">
          <div className="w-full items-center justify-center flex">

          <input
            className="flex items-center justify-center ml-7 w-96 border-2 border-slate-500"
            type="text"
            placeholder="Username"
            value={credential}
            onChange={(e) => setCredential(e.target.value)}
            required
            />
            </div>
        </label>
        <label className="label mt-2  ">
          <div className="w-full items-center justify-center flex">

          <input
            className="  flex items-center justify-center ml-7 w-96 border-2 border-slate-500"
            type="password"
            placeholder="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
            />
            </div>
        </label>
        <div className="flex w-full items-center justify-center">

        <button className="w-36 p-3 h-8  text-white/90 mt-4 mr-4  border text-xs shadow-sm shadow-slate-900 border-gray-700 bg-[#514f4de8] rounded-2xl flex justify-around items-center" type="submit">
          Log In
        </button>
        </div>
      </form>
    </>
  );
}

export default LoginFormModal;
