import * as React from "react"

import {Link} from 'react-router-dom'
const Asia = "https://i.postimg.cc/MHhqbdDL/Asiaregistry.jpg"
    const SouthAmerica ="https://i.postimg.cc/0rR1Jr5R/SAregistry.jpg"
    const NorthAmerica = "https://i.postimg.cc/VkF5mP4L/NAregistry.jpg"
    const Canada = "https://i.postimg.cc/Dw7yCYFV/Canadaregistry.jpg"








export default function Home() {

  return (
    <div>

   <div className="w-[100%] h-14 flex flex-row bg-[#44351d] font-serif">
    <Link href="https://resilience-project.com"> 
    <div className="text-xs h-5 ml-1 mt-4 md:text-lg  items-center flex text-white"> Resilience Project // Tucson

</div>
    </Link>
      <div className="flex ml-[2%]">
    <Link to="/tree">

    <button className="w-[100%] md:w-24 p-3 h-8 md:mr-[40%] lg:ml-[500%] xl:ml-[700%] text-white/90 mt-4   border text-xs shadow-sm shadow-slate-900 border-gray-700 bg-[#514f4de8] rounded-2xl flex justify-around items-center "> Lookup Tree

    </button>
    </Link>
    <Link to="/admin">
    <button className="w-16 md:w-24 h-8 md:mr-[30%] mt-4 lg:ml-[550%] xl:ml-[750%]  text-white/90 text-xs border shadow-sm shadow-slate-900 border-gray-700 bg-[#514f4de8] rounded-2xl justify-center ml-[15%] items-center flex" >Sign In
   
    </button>
    </Link>
      </div>
    </div>
    <div className="">
    <img src="https://i.postimg.cc/nc1DT91H/tree-Welcome.jpg" className="w-full "></img>
    

    </div>
   
   

    
    <div className="bg-black  rounded-xl justify-center items-center flex  mt-8 font-serif text-white"> Info about Registry</div>
    <div className="flex xl:w-full  text-black bg-gray-200 rounded-xl mx-[10%] xl:mx-[0] mt-2 border shadow-sm w-[65%]">
    <div className=" text-sm xl:ml-[5%] xl:items-center  lg:text-2xl ml-auto xl:mt-0 md:mt-16 md:flex  md:text-left md:mr-[15%] ml-[5%] font-serif  ">
    Your donation goes to agroforestry projects led by women and empowering communities throughout the world. Simply choose a region where you would like a tree planted and use your healing to cultivate female empowerment.
<span className="md:mr-[10%] lg:mr-[25%] xl:mr-[6%] ">

</span>
<img src="https://i.postimg.cc/ZR88hM98/sprout.png" className="h-[80%] mt-[5%] md:justify-end lg:w-64 md:h-54 "></img>
    </div>

    </div>
    </div>
  )
}